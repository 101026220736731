export const isBooleanString = (value: unknown): value is 'true' | 'false' => {
  if (typeof value === 'string') {
    return value === 'true' || value === 'false';
  }

  return false;
};

export const parseBooleanString = (value: 'true' | 'false'): boolean => {
  return value === 'true';
};
